<template>
    <div class="wrapper">
    <button :id="guid" class="accordion">{{ Title }}</button>
    <div class="panel">
    <p v-html="Content"></p>
    </div>
    </div>
</template>

<script>
export default {
  name: "Accordion",
  props: {
    Title: String,
    Content: String,
  },
  data() {
    return {
        guid: ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
        (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
        )
    }
  },
  mounted() {
    var acc = document.getElementById(this.guid);
    acc.addEventListener("click", function() {
        this.classList.toggle("active");
        var panel = this.nextElementSibling;
        if (panel.style.display === "block") {
        panel.style.display = "none";
        } else {
        panel.style.display = "block";
        }
    });
  }
 }

</script>

<style scoped>

@media only screen and (max-width: 768px) {

    .wrapper {
        padding-left: 2rem !important;
        padding-right: 2rem !important;
        width: unset !important;
        max-width: unset !important;
    }
}

.wrapper {
    padding-right: 1rem;
    padding-left: 1rem;
    width: 100%;
    max-width: 32rem;
    font-size: 15px;
}

.accordion {
  background-color: #ffffff;
  color: #444;
  cursor: pointer;
  padding: 18px;
  width: 100%;
  font-weight: 600;
  font-size: 16px !important;
  border: none;
  text-align: left;
  outline: none;
  transition: 0.4s;
  margin-top: 1rem;
  border-top-left-radius: 0.75rem;
  border-top-right-radius: 0.75rem;
  border-bottom-left-radius: 0.75rem;
  border-bottom-right-radius: 0.75rem;
  box-shadow: 0px 10px 15px -3px rgba(0,0,0,0.1);
  font-family: 'Poppins', sans-serif !important;
}

.active,
.accordion:hover {
  background-color: #ccc;
}

.active {
    border-bottom-left-radius: 0rem;
    border-bottom-right-radius: 0rem;
}

.panel {
  padding-left: 1rem;
  padding-right: 1rem;
  display: none;
  background-color: white;
  overflow: hidden;
  text-align: left;
  float: left;
  font-size: 16px;
  font-family: 'Poppins', sans-serif !important;
}
</style>
