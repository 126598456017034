<template>
  <div style="display: none;"></div>
</template>

<script>
export default {
  name: 'MetaData',
  props: {
      Title: String,
      Description: String,
      Auhtor: String,
      ImgUrl: String
  },
  mounted () {
    if(this.Title && this.Title !== 'Reklameskærm.dk') {
        document.title = this.Title + ' - Reklameskærm.dk';
        this.addMetaData('title', this.Title);
        this.addMetaData('og:title', this.Title);
        this.addMetaData('twitter:title', this.Title)
    }
    else {
        document.title = 'Reklameskærm.dk - Danmarks digitale mediebureau';
    }
    if(this.Description) {
        this.addMetaData('description', this.Description);
        this.addMetaData('og:description', this.Description);
        this.addMetaData('twitter:description', this.Description);
    }
    if(this.Auhtor) {
        this.addMetaData('author', this.Auhtor);
    }

    if(this.ImgUrl) {
        this.addMetaData('og:image', this.ImgUrl);
        this.addMetaData('twitter:image', this.ImgUrl);
    }
    else {
        this.addMetaData('og:image', 'https://reklameskaerm.dk/img/reklameskaerm_big.e43733d0.png');
        this.addMetaData('twitter:image', 'https://reklameskaerm.dk/img/reklameskaerm_big.e43733d0.png');
    }

    // Generl
    this.addMetaData('og:type', 'website');
    this.addMetaData('og:url', window.location.href);
    this.addMetaData('twitter:url', window.location.href);
    this.addMetaData('twitter:card', 'summary_large_image')
    this.addMetaDataLink('canonical', window.location.href);

  },
  methods: {
    addMetaData(type, value) {
    var meta = document.createElement('meta');
    meta.name = type;
    meta.content = value;
    document.getElementsByTagName('head')[0].appendChild(meta);
  },
  addMetaDataLink(type, value) {
    var meta = document.createElement('link');
    meta.setAttribute('rel', type);
    meta.href = value;
    document.getElementsByTagName('head')[0].appendChild(meta);
  }
  }
}
</script>

