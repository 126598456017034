import { createStore } from 'vuex'

export default createStore({
  state: {
    user: null,
    cartCount: 0,
  },
  mutations: {
    setAuthUser(state, user) {
      state.user = user;

      if(user === null) {
        localStorage.removeItem('user');
        localStorage.removeItem('token');
        localStorage.removeItem('userTimeout');
      }
    },
    addItemToCart(state, item) {
      var basketObjArray = [];

          basketObjArray.push(item);
          
          var currentBasket = localStorage.getItem('basket');

          if(currentBasket != null) {
              var currentBasketObjArray = JSON.parse(currentBasket);
              if(currentBasket.length > 0)
              {
                  // Tjek om den findes i forvejen, hvor den skal overskrives, eller skal den bare indsættes. 
                  currentBasketObjArray.push(item);
              }
              else {
                  currentBasketObjArray = basketObjArray;
              }
              localStorage.setItem('basket', JSON.stringify(currentBasketObjArray));
              state.cartCount = currentBasketObjArray.length;
          }
          else {
              localStorage.setItem('basket', JSON.stringify(basketObjArray));
              state.cartCount = 1;
          }
    },
    overrideItem(state, newItem) {
      var currentBasket = localStorage.getItem('basket');
      if(currentBasket != null) {
        var currentBasketObjArray = JSON.parse(currentBasket);
        if(currentBasketObjArray.length > 0) {
          currentBasketObjArray = currentBasketObjArray.filter(item => item.basketID !== newItem.basketID);
          currentBasketObjArray.push(newItem);
          localStorage.removeItem('basket');
          localStorage.setItem('basket', JSON.stringify(currentBasketObjArray));
        }
      }
    },
    emptyCart(state) {
      localStorage.removeItem('basket');
      state.cartCount = 0;
    },
    removeItemFromCart(state, index) {
      var currentBasket = localStorage.getItem('basket');
      if(currentBasket != null) {
        
        var currentBasketObjArray = JSON.parse(currentBasket);
        
        if(index > -1) {
          currentBasketObjArray.splice(index, 1);
        }

        if(currentBasketObjArray.length === 0)
        {
          localStorage.removeItem('basket');
        }
        else {
          localStorage.setItem('basket', JSON.stringify(currentBasketObjArray));
        }
        
        state.cartCount = currentBasketObjArray.length;
      }
    }
  },
  actions: {
  },
  getters: {
    isLoggedIn(state) {
      var token = localStorage.getItem('token');
      var user = localStorage.getItem('user');
      if(token !== "" && user !== null)
      {
        state.user = user;
      }

      return state.user !== null;
    },
    getCartCount(state) {
      if(state.cartCount > 0)
      {
        return state.cartCount;
      }
      else {
        var sessionCart = localStorage.getItem('basket');
        if(sessionCart !== null) {
          var cartObject = JSON.parse(sessionCart);
          state.cartCount = cartObject.length;
          return state.cartCount;
        }
        else {
          return 0;
        }
      }
    }
  },
  modules: {
  }
})
