<template>

    <div class="spotCard">
        <a :href="'/reklameskaerm/' + link">
        <!-- <div v-if="campaignText.length > 0" class="campaignBanner">
            {{ campaignText }}
        </div> -->
        <div class="spotCard-image">
            <img :src="imageUrl" :alt="AltAttriute">
            <!-- <div v-if="Campaign != null" class="freeShippingRibbon">
                Kampagne {{ Campaign.Percentage }}%
            </div> -->
        </div>
        <div class="spotCard-info">
            <div class="basic">
                <div class="name">{{ title }}</div>
                <div class="location">{{ region }}<span>></span>{{ city }}</div>
                <div class="viewsPrWeek">{{ (viewsPrWeek * 4).toLocaleString() }}+ passerende/md.</div>
            </div>
            <div class="stats">
                <el-row>
                    <!-- <el-col :span="12"><span class="value">{{ ConvertToLocalString(exposure * 4) }}</span><span class="context">afspilninger/md.</span></el-col> -->

                    <el-col :span="24">
                        <span style="color: #31957B;" class="value">{{ $filters.currencyDKK(price) }} pr/uge</span><!--<span style="color: #31957B" class="context">kroner/md.</span>-->
                    </el-col>
                </el-row>
            </div>
        </div>
        </a>
    </div>

</template>

<script>
export default {
  name: 'SpotCard',
  props: {
      id: Number,
      link: String,
      title: String,
      region: String,
      city: String,
      exposure: Number,
      pricePrWeek: Number,
      viewsPrWeek: Number,
      imageUrl: String,
      Campaign: Object,
      AltAttriute: String
  },
  methods: {
      ConvertToLocalString(number) {
        if(!number)
            return (120000).toLocaleString();
        return number.toLocaleString();
      }
  },
  computed: {
    price() {
        // if(this.Campaign) {
        //     return this.pricePrWeek - ((this.pricePrWeek/100) * this.Campaign.Percentage)
        // }
        return this.pricePrWeek;
    }
  }
}
</script>

<style scoped>

.spotCard {
    max-width: 100%;
    width: 100%;
    max-height: 100%;
    height: 300px;
    position: relative;
    cursor: pointer;
    transition: 0.1s;
    float: left;
    padding-right: 2%;
    padding-bottom: 60px;
}

.spotCard a {
    color: black;
    text-decoration: none !important;
}

.spotCard:hover {
    transition: 0.2s;
    opacity: 0.7;
    transform: translate(0px, -5px)
}

div.freeShippingRibbon {
    height: 35px;
    width: 180px;
    position: absolute;
    top: 48px;
    right: -6px;
    text-align: center;
    color: #FFF;
    font-weight: 600;
    font-size: 20px;
    white-space: nowrap;
  -webkit-transform: rotate(35deg);
    -moz-transform: rotate(35deg);
    -ms-transform: rotate(35deg);
    -o-transform: rotate(35deg);
    transform: rotate(35deg);
}

.freeShippingRibbon:before {
    box-sizing: border-box;
    border-style: solid;
    border-color: transparent;
    border-width: 37px;
    border-left-width: 53px;
    border-right-width: 26px;
    content: "";
    display: block;
    left: -49px;
    position: absolute;
    width: 137%;
    border-top-width: 0px;
    border-bottom-color: #9433ff;
    border-bottom-color: linear-gradient(45deg, rgba(148,51,255,1) 0%, rgba(148,51,255,1) 0%, rgba(243,77,229,1) 100%);
    top: -2px;
    z-index: -1;
}

.campaignBanner {
    background-color: red;
    position: relative;
    width: 140px;
    float: right;
    padding: 0 !important;
    top: 60px;
    transform: rotateY(0deg) rotate(45deg);
}

.basic .name {
    width: 100%;
    height: 30px;
    margin-left: 10%;
    padding-top: 4%;
    font-size: 18px;
    font-weight: 500;
    color: rgb(43, 43, 43);
    font-family: 'Poppins', sans-serif;
}

.basic .location {
    width: 100%;
    height: 30%;
    margin-left: 10%;
    font-size: 12px;
    font-weight: 500;
}

.basic .viewsPrWeek {
    width: 100%;
    height: 30%;
    margin-left: 10%;
    font-size: 12px;
    font-weight: 500;
}

.basic .location span {
    padding-left: 2px;
    padding-right: 2px;
    font-weight: 600;
}

/* SVG = FA */
.stats svg {
    color: rgb(128, 128, 128);
    font-size: 14px;
    padding-right: 10px;
    vertical-align: -3px;
}

.stats span {
    font-size: 14px;
    color: rgb(66, 66, 66);
    font-weight: 600;
    width: 100%;
    float: left;
}

.stats :nth-child(1) {
    line-height: 40px;
}

.stats :nth-child(2) {
    font-size:10px;
    line-height: 0px;
}

.stats {
    text-align: center;
    padding-top: 4px;
    height: 60px;
}

.spotCard-image {
    width: 100%;
    height: 80%;
}

.spotCard-image img {
    width: 100%;
    height: 100%;
    object-fit:cover;
    border-radius: 10px;
    border: 1px solid rgb(225, 225, 225);
    box-shadow: 0 4px 6px -1px rgb(0 0 0 / 10%), 0 2px 4px -1px rgb(0 0 0 / 6%);
}

.spotCard-info {
    border: 1px solid #d8d8d8;
    position: relative;
    height: 154px;
    background-color: rgba(240, 240, 240);
    bottom: 60px;
    margin-left: auto;
    margin-right: auto;
    border-radius: 10px;
    width: 90%;
    box-shadow: 0 10px 15px -3px rgb(0 0 0 / 10%), 0 4px 6px -2px rgb(0 0 0 / 5%);
}

.spotCard-info .basic {
    height: 80px;
    width: 100%;
    background-color: white;
    border-radius: 10px 10px 0px 0px;
    padding-bottom: 10px;
}

.spotCard-info .stats {
    width: 100%;
    border-radius: 0px 0px 10px 10px;
    background-color: rgb(247, 247, 247);
    display: inline-block;
}

.value {
    font-weight: 600 !important;
    font-size: 18px !important;
    margin-top: 8px;
}

.context {
    width: 100%;
    font-weight: 600 !important;
    font-size: 12px !important;
}

</style>
