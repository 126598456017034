<template>
  <Header></Header>
  <router-view class="router-view" />
  <div v-if="showCookies" class="cookies">
    <div class="cookies-info">
      Vi benytter cookies på vores hjemmeside. Ved at benytte siden accepteres brugen af cookies. Link til vores: <a href="/hjaelp?goTo=Privatlivspolitik">Privatlivspolitik.</a>
      <div class="close-cookies">
        <i class="el-icon-close" @click="acceptCookies"></i>
      </div>
    </div>
  </div>
  <Footer></Footer>
</template>

<script>
import Header from './components/shared/Header.vue'
import Footer from './components/shared/Footer.vue'

export default {
  name: 'App',
  components: {
    Header,
    Footer
  
  },
  data(){
    return {
      showCookies: true
    }
  },
  mounted() {
    var hasCookiesBeenAccepted = window.localStorage.getItem('hasCookiesBeenAccepted');
    if(hasCookiesBeenAccepted != null && hasCookiesBeenAccepted === 'true')
      this.showCookies = false
  },
  methods: {
    acceptCookies() {
      window.localStorage.setItem('hasCookiesBeenAccepted', 'true');
      this.showCookies = false;
    }
  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Baloo+Tammudu+2:wght@400;500;600;700;800&family=Oswald:wght@200;300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1, h2, h3, h4, h5 {
  font-family: 'Poppins', sans-serif !important;
}

@media only screen and (min-width: 768px) {
    .router-view {
      margin-top: 81px;
    }
}

@media only screen and (max-width: 768px) {
  .router-view {
    margin-top: 0px;
  }

  .breadtext {
    line-height: 25px;
  }
}

.html {
  scroll-behavior: smooth;
}

body {
  margin: 0 auto;
  padding: 0;
  background-color: #f9fafb;
  /* max-width: 1488px; */
  width: 100%;
  font-family: 'Poppins', sans-serif !important;
}

.el-row {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.cookies {
    width: 100%;
    background-color: #9433ff;
    border-top: 1px solid #ededed;
    z-index: 9999;
    position: fixed;
    bottom: 0;
    left: 0;
    color: white;
    align-content: center;
}

.cookies-info {
  max-width: 1488px;
  width: 100%;
  margin: 0 auto;
  text-align: center;
  margin-top: 10px;
}

.cookies span {
  padding-top: 10px;
  padding-bottom: 0px;
}

.cookies a {
  color: white;
}

.close-cookies {
  margin-left: 20px;
  margin-top: 10px;
}

.cookies i {
  font-size: 24px;
  cursor: pointer;
  transition: 0.2s;
}

.cookies i:hover {
  opacity: 0.7;
  transition: 0.2s;
}

</style>
