<template>
  <div class="footer">
    <el-row :gutter="20">
        <a href="/faa-jeres-egen-reklameskaerm">
        <el-col :span="24"><span class="link">Få jeres egen reklameskærm</span></el-col>
        </a>
    </el-row>
    <el-row :gutter="20">
        <a href="/hjaelp">
        <el-col :span="24"><span class="link">Hjælp og info</span></el-col>
        </a>
    </el-row>
    <el-row :gutter="20">
        <a href="/artikler-og-cases">
        <el-col :span="24"><span class="link">Artikler og cases</span></el-col>
        </a>
    </el-row>
    <el-row :gutter="20">
        <a href="/hjaelp?goTo=Privatlivspolitik">
        <el-col :span="24"><span class="link">Privatlivspolitik</span></el-col>
        </a>
    </el-row>
    <el-row :gutter="20">
        <a href="/hjaelp?goTo=ForSkaermudbyder">
        <el-col :span="24"><span class="link">For skærmudbydere</span></el-col>
        </a>
    </el-row>
    <el-row :gutter="20">
        <a href="/hjaelp?goTo=Handelsbetingelser">
        <el-col :span="24"><span class="link">Handelsbetingelser</span></el-col>
        </a>
    </el-row>
    <el-row :gutter="20">
        <a href="/hjaelp?goTo=KontaktOs">
        <el-col :span="24"><span class="link">Kontakt os</span></el-col>
        </a>
    </el-row>
    <el-row :gutter="20">
        <el-col :span="24"><span><img src="https://www.freepnglogos.com/uploads/visa-and-mastercard-logo-26.png" alt="visa-mastercard" width="75" height="23"></span></el-col>
    </el-row>
    <el-row :gutter="20">
        <el-col :span="24">© 2023 Reklameskærm.dk</el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: 'Footer'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.footer {
    color: #909399;
    font-weight: 600;
    border-top: 1px solid rgb(204 204 204);
    padding-top: 20px;
    padding-bottom: 75px;
}

.el-button--text {
    color: #909399;
    font-weight: 600;
    font-size: 16px;
}

.el-button--text:hover {
    color: #303133;
}



.el-row {
    text-align: center;
}

.el-row a {
    margin: 0 auto;
    text-decoration: none !important;
    color: #909399;
}

</style>
