<template>
    <div class="page-wrapper" :style="{ 'background-color': backgroundColor, 'color': color + ' !important' }">
        <el-row class="container" :gutter="24">
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                <div class="content" v-html="content" />
            </el-col>
        </el-row>
    </div>
</template>

<script>
// @ is an alias to /src

export default {
    name: 'Textblock',
    components: {
    },
    props: {
        BackgroundColor: String,
        Content: String,
        Color: String
    },
    data() {
        return {
            backgroundColor: this.BackgroundColor ?? 'white',
            content: this.Content ?? `Content`,
            color: this.Color ?? 'black'
        }
    },
    mounted() {
    }
}
</script>

<style scoped>

@media only screen and (max-width: 768px) {
    /* For mobile phones: */
    .page-wrapper {
      width: 100% !important;
      padding-left: 0px !important;
      padding-right: 0px !important;
    }

    .page-wrapper h2 {
      padding-left: 20px;
    }
}

.container {
    width: 1000px;
    max-width: 100%;
    margin: 0 auto !important;
    padding: 10px 10px 10px 10px;
    position: relative;
}

.container:not(:has(h1)) {
    padding: 0px 10px 10px 0px !important;
}

h1 {
    padding-top: 2rem;
    font-weight: 400;
}

h1 ::v-deep span {
    font-weight: 600;
}

.content ::v-deep h2 {
    font-size: 28px !important;
    font-weight: 400;
    color: unset;
}

.content ::v-deep h2 span {
    font-weight: 600 !important;
    color: unset;
}

.container, .container h2 {
    font-family: 'Poppins', sans-serif !important;
}

img {
    object-fit: cover;
    display: block;
    height: auto;
    margin: auto;
}

.page-wrapper {
  line-height: 25px;
  width: 100%;
  margin: 0 auto;
  padding-bottom: 1rem;
  padding-top: 1rem;
}

</style>
