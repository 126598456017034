<template>
    <div class="page-wrapper" :style="{ 'background-color': backgroundColor }">
        <el-row class="container" :gutter="24" v-if="positioning === 'left'">
            <el-col v-if="Headline" :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                <h1 v-html="Headline"></h1>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                <el-row>
                    <el-col >
                       <div class="content" v-html="content" />
                    </el-col>
                </el-row>
            </el-col>
           
            <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                <img width="450" height="450" class="left-image" :src="imageUrl" :imageAlt="imageAlt"/>
            </el-col>
        </el-row>

        <el-row class="container right" :gutter="24" v-if="positioning === 'right'">
            <el-col v-if="Headline" :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                <h1 v-html="Headline"></h1>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                <img width="450" height="450" class="right-image" :src="imageUrl" :imageAlt="imageAlt"/>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                <el-row>
                    <el-col >
                       <div class="content" v-html="content" />
                    </el-col>
                </el-row>
            </el-col>
        </el-row>
    </div>
</template>

<script>
// @ is an alias to /src

export default {
    name: 'TextWithSideImage',
    components: {
    },
    props: {
        BackgroundColor: String,
        MinHeight: Number,
        Headline: String,
        Content: String,
        ImageUrl: String,
        ImageAlt: String,
        Position: String
    },
    data() {
        return {
            backgroundColor: this.BackgroundColor ?? 'white',
            minHeight: this.MinHeight ?? '20',
            
            content: this.Content ?? `Content`,
            imageUrl: this.ImageUrl ?? 'https://strapiuploadac.s3.eu-north-1.amazonaws.com/HP_Hvidevarer_reklameskaerm_storskaerm_Viborgvej_book_kampagne_Skive_1_1c1f622aa8_72f8eee5cf.webp',
            imageAlt: this.ImageAlt ?? 'ImageAlt',
            positioning: this.Position ?? 'left'
        }
    },
    mounted() {
    }
}
</script>

<style scoped>

@media only screen and (min-width: 768px) {
    /* For mobile phones: */
    .left-image, .right-image {
        padding-top: 1.5rem !important;
    }
}

@media only screen and (max-width: 768px) {
    /* For mobile phones: */
    .page-wrapper {
      width: 100% !important;
      padding-left: 0px !important;
      padding-right: 0px !important;
      padding-bottom: 2rem !important;
    }

    h1 {
        line-height: 2.5rem;
    }

    .page-wrapper h2 {
      padding-left: 20px;
    }

    .left-image {
        padding-top: 2rem !important;
        padding-bottom: 1rem !important;
    }

    .right-image {
        padding-bottom: 2rem !important;
    }

    img {
        width: 100%;
    }

    .right {
        display: flex; 
        flex-direction: column-reverse;
    }
}

.container {
    width: 1000px;
    max-width: 100%;
    margin: 0 auto !important;

    position: relative;
}

h1 {
    padding-top: 2rem;
    font-weight: 400;
}

h1 ::v-deep span {
    font-weight: 600;
}

.content ::v-deep a {
    color: black;
}

.content ::v-deep h2 {
    font-size: 28px !important;
    font-weight: 400;
    color: black !important;
}

.content ::v-deep h2 span {
    font-weight: 600 !important;
    color: black;
}

.container, .container h2 {
    font-family: 'Poppins', sans-serif !important;
}

img {
    object-fit: cover;
    display: block;
    height: auto;
    margin: auto;
}

.page-wrapper {
  line-height: 25px;
  width: 100%;
  margin: 0 auto;
  padding-bottom: 4rem;
  padding-top: 1rem;
}

</style>
