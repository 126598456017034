<template>
  <div class="spotPage">
  <meta-data :Title="'Annoncér på Storskærme i hele Danmark!'" :Description="'Få jeres budskab vist på én eller flere storskærme i hele Danmark! Det tager blot få minutter at booke en skærm og uploade jeres annonce!'" />
    <!-- Cards -->
    <el-container>
      <el-main>
        
        <div class="filters">
          <div class="selectRegion">
          <el-select v-model="selectedRegion" clearable  placeholder="Region">
            <el-option
              v-for="region in regions"
              :key="region.value"
              :label="region.label"
              :value="region.value">
            </el-option>
          </el-select>
        </div>
        <!-- <div class="selectExposure">
          <el-select v-model="selectedPriceRange" clearable  placeholder="Pris">
            <el-option
              v-for="priceRange in priceRanges"
              :key="priceRange.value"
              :label="priceRange.label"
              :value="priceRange.value">
            </el-option> 
          </el-select>
        </div> -->
        <el-button @click="filterSpots" class="search" icon="el-icon-search" type="success">Søg</el-button>
      </div>

      <span v-if="numberOfSpotsToShow !== 0" class="numberOfSpots">Fandt {{ numberOfSpotsToShow }} ud af {{ numberOfSpots }} resultater</span> 
      <span v-if="isLoading" class="numberOfSpots">Henter alle tilgængelige skærme</span>
      <span v-if="numberOfSpotsToShow === 0 && !isLoading" class="numberOfSpots">Din søgning gav desværre ingen resultater</span>

      <el-row :gutter="24" style="overflow: hidden;">
        <el-col :xs="24" :sm="24" :md="15" :lg="15" :xl="15">
        <el-row :gutter="12">
          <el-col  style="margin-top: 5px;" :xs="24" :sm="8" :md="8" :lg="8" :xl="8" v-for="spot in spots.filter(prop => prop.Show)" :key="spot.ID">
          <spot-card
            :id="spot.id"
            :link="spot.Link"
            :title="spot.Title"
            :region="spot.Region"
            :city="spot.City"
            :viewsPrWeek="spot.ViewsPrWeek"
            :exposure="spot.Exposure"
            :pricePrWeek="spot.PricePrWeek"
            :imageUrl="spot.ImageUrl"
            :Campaign="spot.Campaign"
            :AltAttriute="spot.AltAttribute"
          ></spot-card>
          </el-col>
        </el-row>
        </el-col>
          <el-col :xs="24" :sm="24" :md="9" :lg="9" :xl="9">
            <map-box :spots="spots" :propShowPupup="true" class="mapboxref" ref="mapbox"></map-box>
          </el-col>
        </el-row>

        <el-row>
          <el-col>
            <div class="seoContent">
            Udendørs reklameskærme har i de seneste år vundet stigende popularitet blandt markedsføringsfolk, 
            da de giver mulighed for at nå et bredt publikum på en effektiv måde. 
            Disse skærme kan placeres på offentlige steder som bymidter, shoppingcentre og sportsarenaer, 
            og de kan vise alt fra reklamevideoer til interaktive kampagner. Her er nogle af de markedsføringsmuligheder, 
            der er forbundet med udendørs reklameskærme:

            <ul>
              <li>Skærme med høj opløsning: En af de største fordele ved udendørs reklameskærme er, at de har en høj opløsning og kan vise billeder og videoer i høj kvalitet. Dette betyder, at markedsførere kan bruge skærmene til at vise deres produkter og tjenester på en måde, der er mere tiltalende og opsigtsvækkende end traditionelle reklameformer.</li>
              <li>Større synlighed: Reklameskærme, der er placeret på strategiske steder som i bymidter eller i nærheden af trafikknudepunkter, kan give en større synlighed for virksomheder. De kan tiltrække mere opmærksomhed end traditionelle reklameformer og hjælpe med at øge bevidstheden om virksomhedens brand.</li>
              <li>Fleksibilitet: Udendørs reklameskærme er også fleksible, da de kan opdateres og ændres i realtid. Dette betyder, at markedsførere kan tilpasse deres kampagner i henhold til ændringer i forbrugeradfærd eller aktuelle begivenheder.</li>
            </ul>

            I det hele taget er udendørs reklameskærme en effektiv måde at nå ud til et bredt publikum på og kan give virksomhederne en større synlighed og tiltrække mere opmærksomhed end traditionelle reklameformer. Med deres høje opløsning og fleksibilitet kan skærmene bruges til en lang række markedsføringsmuligheder og engagerende kampagner.
            </div>
          </el-col>
        </el-row>
      </el-main>
    </el-container>
  </div>
</template>

<script>

import SpotCard from '../components/layouts/SpotCard.vue'
import MapBox from '../components/shared/Map.vue'
import axios from 'axios'
import qs from 'qs'
import MetaData from '../components/shared/MetaData.vue'

export default {
  components: { SpotCard, MapBox, MetaData },
  name: 'SpotOverview',
  data() {
    return {
      selectedPriceRange: '',
      selectedRegion: '',
      spots: [],
      regions: [],
      priceRanges: [],
      priceRangesObjects: [],
      markers: [],
      isLoading: true,
    }
  },
  computed: {
    numberOfSpotsToShow() {
      return this.spots.filter(s => s.Show === true).length;
    },
    numberOfSpots() {
      return this.spots.length;
    }
  },
  mounted() {
      this.getAllSpots();
      this.getRegions();
      this.getPriceRanges();
  },
  methods: {
    convertResponseToModel(response) {
      const attributes = response.attributes;
    
      let priceLevels = [];
      if(attributes.UsePriceLevel1 !== null && attributes.UsePriceLevel1) {
        priceLevels.push({ views: (attributes.Exposure / 100) * 20, price: (attributes.PricePrWeek / 100) * 20, label: '2' })
      }
      if(attributes.UsePriceLevel2 !== null && attributes.UsePriceLevel2) {
        priceLevels.push({ views: (attributes.Exposure / 100) * 40, price: (attributes.PricePrWeek / 100) * 40, label: '4' })
      }
      if(attributes.UsePriceLevel3 !== null && attributes.UsePriceLevel3) {
        priceLevels.push({ views: (attributes.Exposure / 100) * 60, price: (attributes.PricePrWeek / 100) * 60, label: '6' })
      }
      if(attributes.UsePriceLevel4 !== null && attributes.UsePriceLevel4) {
        priceLevels.push({ views: (attributes.Exposure / 100) * 80, price: (attributes.PricePrWeek / 100) * 80, label: '8' })
      } 
      if(attributes.UsePriceLevel4 !== null && attributes.UsePriceLevel5) {
        priceLevels.push({ views: attributes.Exposure, price: attributes.PricePrWeek, label: '10' })
      }

      let price = attributes.PricePrWeek;
      let views = attributes.ViewsPrWeek;

      // OBS: DER ER ET PROBLEM, HVOR DET IKKE ER ALLE USEPRICELEVELS, SOM KOMMER KORREKT UD FRA STRAPI!
      // if(priceLevels.length > 0) {
      //   const level = priceLevels[0];
      //   price = level.price;
      //   views = level.views;
      // }

      

      return {
        id: response.id,
        citrusmedia: attributes.Citrusmedia,
        Link: attributes.HumanizedLink,
        Title: attributes.Title,
        Region: attributes.region.data.attributes.Name,
        regionID: attributes.region.data.id,
        City: attributes.City,
        Exposure: attributes.Exposure,
        ImageUrl: attributes.Images.data[0].attributes.formats.medium.url,
        AltAttribute: attributes.Images.data[0].attributes.alternativeText,
        PricePrWeek: price,
        PositionLat: attributes.PositionLat,
        PositionLong: attributes.PositionLong,
        ViewsPrWeek: views,
        ShowPopUp: true,
        Campaign: attributes.spot_savings.data[0]?.attributes,
        Show: true,
      };

    },
    getAllSpots() {
      var query = qs.stringify({
        populate: '*'
      });

       axios.get(`${process.env.VUE_APP_STRAPI_API_URL}spots/?${query}`, )
          .then(response => {
              for(var index = 0; index < response.data.data.length; index++)
              {
                var spotFromStrapi = response.data.data[index];
                var convertedSpot = this.convertResponseToModel(spotFromStrapi);
                

                if(spotFromStrapi.attributes.spot_savings.data.length > 0)
                {
                  var compaign = spotFromStrapi.attributes.spot_savings.data[0].attributes; 
                  convertedSpot.Campaign = {
                    Percentage: compaign.Percentage,
                    Weeks: compaign.Weeks
                  };
                }

                this.spots.push(convertedSpot);

                this.spots = this.spots.sort((a, b) => {
                              if (a.citrusmedia === b.citrusmedia) {
                                  return 0;
                              } else if (a.citrusmedia) {
                                  return 1; // `a` is true, should come after `b`
                              } else {
                                  return -1; // `a` is false, should come before `b`
                              }
                          });
              }

              this.$refs.mapbox.initializeMap();
              this.isLoading = false;
          })
    },
    getRegions() {
      axios.get(`${process.env.VUE_APP_STRAPI_API_URL}regions`, )
          .then(response => {
              for(var index = 0; index < response.data.data.length; index++)
              {
                var region = response.data.data[index];
                

                this.regions.push({
                  value: region.id,
                  label: region.attributes.Name
                });
              }
          })
    },
    getPriceRanges() {
      axios.get(`${process.env.VUE_APP_STRAPI_API_URL}Price-ranges`, )
          .then(response => {
              this.priceRangesObjects = response.data.data.sort((a, b) => a.id - b.id);
              for(var index = 0; index < response.data.data.length; index++)
              {
                var priceRange = response.data.data[index].attributes;

                if(priceRange.To !== null) {
                  this.priceRanges.push({
                    value: index,
                    label: this.$filters.currencyDKK(priceRange.From) + " - " + this.$filters.currencyDKK(priceRange.To)
                  });
                }
                else {
                  this.priceRanges.push({
                    value: index,
                    label: "Over " + this.$filters.currencyDKK(priceRange.From)
                  });
                }
              }
          });
    },
    filterSpots() {
      for(var index = 0; index < this.spots.length; index++)
      {
        var spot = this.spots[index];
        if(this.selectedRegion > 0 && this.selectedPriceRange.length === "")
        {
            if(spot.regionID === this.selectedRegion && (spot.PricePrWeek >= this.selectedPriceRange.From && spot.PricePrWeek <= this.selectedPriceRange.To))
            {
              spot.Show = true;
            }
            else 
            {
              spot.Show = false;
            }
        }
        else if (this.selectedRegion > 0 && this.selectedPriceRange !== "") {
          if(spot.regionID === this.selectedRegion) {
            spot.Show = true;
          }
          else {
            spot.Show = false;
          }

          var priceRangeCom = this.priceRangesObjects[this.selectedPriceRange].attributes;
          
          if(spot.Show === true && spot.PricePrWeek >= priceRangeCom.From && spot.PricePrWeek <= priceRangeCom.To) 
          {
            spot.Show = true;
          }
          else {
            spot.Show = false
          }
        }
        else if (this.selectedRegion > 0)
        {
          if(spot.regionID === this.selectedRegion) {
            spot.Show = true;
          }
          else {
            spot.Show = false;
          }
        }
        else if (this.selectedPriceRange !== "") 
        {
          var priceRange = this.priceRangesObjects[this.selectedPriceRange].attributes;
          if(spot.PricePrWeek >= priceRange.From && spot.PricePrWeek <= (priceRange.To != undefined ? priceRange.To : 9999999999999)) 
          {
            spot.Show = true;
          }
          else {
            spot.Show = false
          }
        }
        else {
          spot.Show = true;
        }
      }

      this.$refs.mapbox.refreshMarkersOnMap();
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

@media only screen and (min-width: 768px) {
    /* For mobile phones: */
    .spotPage {
      border-top: 1px solid rgb(204 204 204);
    }
}

@media only screen and (max-width: 768px) {
    /* For mobile phones: */
    .spotPage {
      margin-top: 50px !important;
    }

    .mapboxref {
      height: 400px;
    }
}

@media only screen and (max-width: 600px) {
    /* For mobile phones: */
    .selectExposure {
      float: right !important;
      margin-left: 0px !important;
    }

    .selectRegion {
      width: 100%;
      float: left;
      margin-left: 0px;
    }

    .selectRegion .el-select {
      width: 100% !important;
      padding-bottom: 20px;
    }

    .search, .search:focus, .search:active {
      width: 100%;
      float: left;
      margin-left: 0px !important;
    }
}

@media only screen and (max-width: 486px) {
    /* For mobile phones: */
    .selectExposure {
      width: 49%;
      float: left;
      margin-left: 0px !important;
      padding-left: 1px;
    }

    .selectRegion {
      width: 100%;
      float: left;
      margin-left: 0px;
      padding-right: 1px;
    }

    .selectRegion .el-select {
      width: 100% !important;
      padding-bottom: 20px;
    }
}

@media only screen and (max-width: 579px) {
    /* For mobile phones: */
    .search, .search:focus, .search:active {
      width: 100%;
      float: left;
      margin-left: 0px !important;
    }
}

.spotPage {
  width: 100%;
  max-width: 1488px;
  margin: 0 auto;
  margin-top: 80px;
}

.mapboxref {
  border-radius: 10px;
}

.filters {
  padding-bottom: 20px;
  margin-left: 4px;
  width: 100%;
  float: left;
}

.selectExposure, .selectRegion {
  float: left;
}

.selectExposure {
  margin-left: 20px;
}

.search, .search:focus, .search:active {
  float: left;
  margin-left: 20px;
}

.numberOfSpots {
  font-weight: 600; 
  margin-left: 5px;  
  font-size: 18px;
  width: 100%;
}

.seoContent {
  width: 50%;
  margin: 0 auto;
  margin-top: 50px;
  object-fit: contain;
  text-align: left;
  opacity: 1;
  font-size: 16px;
}

.seoContent ul {
  list-style-type: decimal;
}

.seoContent li:not(:last-child) { 
   margin-bottom: 1rem;  
}

@media only screen and (max-width: 1200px) {
    .seoContent {
      width: 80% !important;
    }
}

</style>
