<template>
  <div></div>
</template>
<script>

export default {
  name: 'sitemap',
  created() {
    window.location.href = "https://reklameskaerm-strapi-prod.herokuapp.com/sitemap/index.xml";
  },
};
</script>