import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vClickOutside from 'click-outside-vue3'
// import installElementPlus from './plugins/element'
import ElementPlus from 'element-plus';
import 'element-plus/lib/theme-chalk/index.css';

// Font Awsome 
import { library } from '@fortawesome/fontawesome-svg-core'
import { faUserSecret, faPhone, faTag, faEye , faBars, faUserCircle, faUser, faArrowLeft, faSignInAlt, faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import axios from 'axios';
import "./assets/global.css";
import "./assets/elementPlusOverrides.css";
import Markdown from 'vue3-markdown-it';

library.add(faUserSecret);
library.add(faPhone);
library.add(faTag);
library.add(faEye);
library.add(faBars);
library.add(faUserCircle);
library.add(faUser);
library.add(faArrowLeft);
library.add(faSignInAlt);
library.add(faTimes);

const app = createApp(App).component("fa", FontAwesomeIcon)
app.use(ElementPlus)
app.use(Markdown);

app.config.globalProperties.$filters = {
    currencyDKK(value) {
        var formatter = new Intl.NumberFormat('da-DK', {
            style: 'currency',
            currency: 'DKK',
            minimumFractionDigits: 0
        });
        return formatter.format(value)
    }
}

app.directive('click-outside', {
  bind: function (el, binding, vnode) {
    console.log(el)
    this.event = function (event) {
     if (!(el == event.target || el.contains(event.target) || event.target.className === "select2-selection__choice__remove" || event.target.className === "select2-search__field" )) {
        vnode.context[binding.expression](event);
      }
    };
    document.body.addEventListener('click', this.event)
  },
  unbind: function () {
    document.body.removeEventListener('click', this.event)
  },
});

axios.interceptors.request.use(function(config) {
    let token = localStorage.getItem('token');
    
    if(token && !config.url.includes(process.env.VUE_APP_STRAPI_API_URL)) {
        config.headers['Authorization'] = `Bearer ${token}`;
    }

    config.headers['Accept'] = '*/*';

    return config
},
(error) => {
    return Promise.reject(error);
});


app.use(store).use(router).use(vClickOutside).mount('#app')