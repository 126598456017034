<template>
  <meta-data 
        :Title="'Reklameskærm.dk'" 
        :Description="'Danmarks førende mediebureau for små og mellemstore virksomheder. Bliv set på digitale reklameskærme i hele Danmark!'"
        :Auhtor="'Reklameskærm.dk'"
        />
  <div class="frontpage">
        <!-- Welocome message -->
    <el-row :gutter="24" class="topbox frontpage-container">
      <el-col :xs="0" :md="2" :lg="4" :xl="6"></el-col>
      <el-col :xs="24" :md="10" :lg="8" :xl="6">
        <h1><span>Bliv</span> top-of-mind <span>hos jeres</span> kunder</h1>
        <p>Vær sikker på, at dine kunder husker dit brand eller event som det første, ved at have dine budskaber hvor dine kunder er.</p>
        <a href="/reklameskaerme">
          <button class="btn btn-green btn-lg btn-rounded">
              Book en reklameskærm
          </button>
        </a>
      </el-col>
      <el-col :xs="24" :md="10" :lg="8" :xl="6">
        <iframe width="100%" height="340" style="margin-top: 45px" src="https://www.youtube-nocookie.com/embed/yOB5wVctvYo?controls=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
      </el-col>
      <el-col :xs="0" :md="2" :lg="4" :xl="6"></el-col>
    </el-row>
    
    <!-- Top spots -->
    <el-row :gutter="24" class="frontpage-container first-container top-spots" style="padding-top: 2rem;">
      <el-col :xs="0" :md="2" :lg="4" :xl="6"></el-col>
      <el-col :xs="24" :sm="24" :md="20" :lg="16" :xl="12" class="top-spot-card">
        <el-row :gutter="20">
          <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8" class="spotCardsList" v-for="spotCard in topSpotCards" :key="spotCard.ID">
            <spot-card
            :id="spotCard.id" 
            :link="spotCard.link"
            :title="spotCard.title" 
            :region="spotCard.region" 
            :city="spotCard.city" 
            :viewsPrWeek="spotCard.viewsPrWeek"
            :exposure="spotCard.exposure"
            :pricePrWeek="spotCard.pricePrWeek"
            :imageUrl="spotCard.imageUrl"
            :AltAttriute="spotCard.altAttribute"
            :Campaign="spotCard.Campaign"
            class="card" />
          </el-col>
        </el-row>
      </el-col>
      <el-col :xs="0" :md="2" :lg="4" :xl="6"></el-col>
    </el-row>
    <el-row :gutter="24">
      <el-col :xs="2" :md="8" :lg="8" :xl="8"></el-col>
      <el-col :xs="20" :md="8" :lg="8" :xl="8">
        <a href="/reklameskaerme">
        <button class="btn btn-green btn-lg btn-rounded" style="width: 100%; margin-top: 2rem;">Se alle {{spotCount}} skærme</button>
        </a>
      </el-col>
      <el-col :xs="2" :md="8" :lg="8" :xl="8"></el-col>
    </el-row>

    <div class="max-width" style="max-width: 1180px !important">
      <el-row :gutter="24" class="frontpage-container customer-images" style="padding-bottom: 4rem;">
        <el-col :xs="2" :sm="2" :md="2" :lg="2" :xl="2"></el-col>
        <el-col :xs="20" :sm="20" :md="20" :lg="20" :xl="20">
          <p style="font-size: 24px">Et udpluk af vores <b>annoncører</b></p>
        </el-col>
        <el-col :xs="2" :sm="2" :md="2" :lg="2" :xl="2"></el-col>
        <el-col :xs="2" :sm="2" :md="2" :lg="2" :xl="2"></el-col>
        <el-col :xs="24" :sm="4" :md="4" :lg="4" :xl="4"><a href="https://www.nygartdk.dk/" target="_blank"><img src="@/assets/images/customers/ak.png" alt="ak nygart" class="worker-img" /></a></el-col>
        <el-col :xs="24" :sm="4" :md="4" :lg="4" :xl="4"><a href="https://akkc.dk/" target="_blank"><img src="@/assets/images/customers/AKKC_logo.png" alt="Aalborg Kultur og Kongress center" class="worker-img" /></a></el-col>
        <el-col :xs="24" :sm="4" :md="4" :lg="4" :xl="4"><a href="https://racehall.com/" target="_blank"><img src="@/assets/images/customers/Racehall_reklameskaerm.png" alt="Racehall" class="worker-img" /></a></el-col>
        <el-col :xs="24" :sm="4" :md="4" :lg="4" :xl="4"><a href="https://www.louisnielsen.dk/" target="_blank"><img src="@/assets/images/customers/Louis-Nielsen_reklameskaerm.png" alt="Louis Nielsen" class="worker-img" /></a></el-col>
        <el-col :xs="24" :sm="4" :md="4" :lg="4" :xl="4"><a href="https://netto.dk/" target="_blank"><img src="@/assets/images/customers/Netto.png" alt="Netto" class="worker-img" /></a></el-col>
        <el-col :xs="2" :sm="2" :md="2" :lg="2" :xl="2"></el-col>
        <el-col :xs="0" :sm="2" :md="2" :lg="2" :xl="2"></el-col>
        <el-col :xs="20" :sm="20" :md="20" :lg="20" :xl="20">
          <q><i>
            Vi har været utroligt glade for jeres service, og særligt for jeres kundeservice. I har været utroligt imødekommende og
            personlige i jeres måde at hjælpe os på 😊 Det er rart når man kan mærke, at der sidder et menneske bag skærmen,
            der oprigtigt har et ønske om at hjælpe en, og gøre det til en god oplevelse.
          </i></q>
          <p>– Maria Teglkamp, Marketingschef hos Ak Nygart</p>
        </el-col>
      </el-row>
    </div>
      

    <!-- Short introduction -->
    <el-row class="frontpage-container introduction-container" id="scrollHere" style="padding-top: 2rem; padding-bottom: 2rem;">
      <el-col :span="24" class="center-text steps">
        <el-row class="steps-row">
          <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8">
            <el-row>
              <el-col :span="24"><h2>1. Find skærm</h2></el-col>
              <el-col :span="24" class="step-description">Vælg én eller flere skærme</el-col>
            </el-row>
          </el-col>
          <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8">
            <el-row>
              <el-col :span="24"><h2>2. Vælg periode</h2></el-col>
              <el-col :span="24" class="step-description">Angiv de uger I ønsker at annoncere</el-col>
            </el-row>
          </el-col>
          <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8">
            <el-row>
              <el-col :span="24"><h2>3. Upload grafik</h2></el-col>
              <el-col :span="24" class="step-description">Upload jeres billede eller video</el-col>
            </el-row>
          </el-col>
        </el-row>
      </el-col>
    </el-row>

    <!-- For users-->
    <el-row class="frontpage-container">
    </el-row>
    <el-row style="padding-top: 4rem; padding-bottom: 4rem;">
      <el-col :xs="0" :sm="2" :md="2" :lg="4" :xl="6"></el-col>
      <el-col :xs="24" :sm="10" :md="10" :lg="8" :xl="6" class="find-the-perfect-screen">
        <h2>Hvordan bruger jeg Reklameskærm.dk?</h2>
        <div style="line-height: 2rem;" class="breadtext">
        Hos Reklameskaerm.dk har vi gjort det nemt at få jeres virksomheds budskaber ud til den brede befolkning. 
        Vælg blot de skærme I ønsker og upload jeres grafik. Nemmere bliver det ikke.
        <br><br>
        <div class="iframeContainer">
        <iframe class="responsive-iframe" src="https://www.youtube-nocookie.com/embed/_TWDoB1iBTU?controls=0" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        </div>
        <br>
        </div>
        <el-row>
          <el-col :span="24" class="seeScreens">
            <button class="btn btn-green btn-lg btn-rounded" @click="$router.push('/reklameskaerme');">
              Se alle reklameskærme
            </button>
          </el-col>
        </el-row>
        </el-col>
      <el-col :xs="24" :sm="10" :md="10" :lg="8" :xl="6" class="padding-lr-40"><map-box :spots="mapSettings.spots" :height="500" :propShowPupup="mapSettings.showPopup" class="mapboxref" ref="mapbox"></map-box></el-col>
      <el-col :xs="0" :sm="2" :md="2" :lg="4" :xl="6"></el-col>
    </el-row>

    <!-- Our users-->
    <el-row class="frontpage-container" style="display: none">
      <el-col :span="24" class="center-text">
        <h2>Vores annoncører (Skal gemmes indtil vi har nogen af vise)</h2>
      </el-col>
    </el-row>

    <el-row :gutter="24" class="frontpage-container introduction-container" style="padding-top: 4rem; padding-bottom: 4rem; background-color: #9433ff; color: white;">
      <el-col  :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="font-size: 18px">
       <Textblock Color="white" BackgroundColor="#9433ff" Content="
        <center><h2><b>VISNINGS</b>GARANTI</h2></center>
        <p>I bestemmer hvor meget jeres reklameplads minimum skal vises på den pågældende skærm.</p>
        <p>Det gør I ved at tilgå skærmen, og justere i belægningsgraden.<br />
        Procenten angiver hvor meget jeres reklameplads vil fylde af skærmens samlede visningstid.
        </p>
        <p>Kort fortalt. Hos Reklameskærm.dk får I visninger med garanti.</p>
        
       " />
      </el-col>
    </el-row>

    <!-- What is a good  -->
    <el-row :gutter="24" class="frontpage-container introduction-container" style="padding-top: 4rem; padding-bottom: 4rem; margin-top: 0px !important;">
      <el-col  :xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="center-text steps">
        <h2 style="line-height: 2rem" >Skal jeg bruge reklameskærme?</h2>
      </el-col>
      <el-col :xs="0" :sm="2" :md="2" :lg="4" :xl="7"></el-col>
      <el-col :xs="24" :sm="10" :md="10" :lg="8" :xl="5" class="ifYou yes">
        <p class="center-text" style="font-size: 20px"><b>Ja</b>, hvis du ...</p>
        <ul>
          <li>investerer i dit brand og kendskabsgrad</li>
          <li>har et kort og præcist budskab</li>
          <li>vil spare tid på outdoor markedsføring</li>
          <li>løbende vil kunne udskifte dit budskab</li>
          <li>vil nå en bred målgruppe</li>
        </ul>
      </el-col>
      <el-col :xs="24" :sm="10" :md="10" :lg="8" :xl="5" class="ifYou no">
        <p class="center-text" style="font-size: 20px"><b>Nej</b>, hvis du ...</p>
        <ul>
          <li>forventer målbart konverterings-data</li>
          <li>vil kommunikere et komplekst budskab</li>
        </ul>
      </el-col>
      <el-col :xs="0" :sm="2" :md="2" :lg="4" :xl="7"></el-col>
    </el-row>

    <!-- About us -->
    <el-row class="frontpage-container" style="padding-top: 4rem; padding-bottom: 1rem;">
      <el-col :span="24" class="center-text">
        <h2>Hvem er vi?</h2>
      </el-col>
      <el-col :xs="0" :sm="2" :md="2" :lg="4" :xl="6"></el-col>
      <el-col :xs="24" :sm="10" :md="10" :lg="8" :xl="6" style="padding-top: 2rem" class="breadtext">
        Vi er to ambitiøse sjæle, som har sat os for at gøre markedsføring i det
         udendørs miljø mere tilgængeligt for alle erhvervsdrivende. <br/><br/>
          Med interesse for tech, optimering af processer og interesse i den gode kundeoplevelse,
          har vi kreeret Danmarks største bookingplatform for outdoor markedsføring.<br /><br/>

         
      </el-col>
      <el-col :xs="24" :sm="10" :md="10" :lg="8" :xl="6">
        <div class="max-width" style="padding-bottom: 20px; padding-top: 20px;">
      <el-row style="padding-bottom: 20px;" gutter="10">
                        <el-col :xs="0" :sm="0" :md="2" :lg="2" :xl="4" />
                        <el-col :xs="12" :sm="12" :md="10" :lg="10" :xl="10">
                            <div class="workerCard">
                                <div class="worker-img martin-img" />
                                <div class="Name">Martin Tiedemann</div>
                                <div class="Role">Salg og markedsføring</div>
                            </div>
                        </el-col>
                        <el-col :xs="12" :sm="12" :md="10" :lg="10" :xl="10">
                            <div class="workerCard">
                                <div class="worker-img steffen-img" />
                                <div class="Name">Steffen H. Nielsen</div>
                                <div class="Role">Web ansvarlig</div>
                            </div>
                        </el-col>
                        <el-col :xs="0" :sm="0" :md="2" :lg="2" :xl="2" />
                    </el-row>
      </div>
      </el-col>
      <el-col :xs="0" :sm="2" :md="2" :lg="4" :xl="6"></el-col>

      <el-col :xs="0" :sm="2" :md="2" :lg="4" :xl="6"></el-col>
      <el-col :xs="24" :sm="20" :md="20" :lg="16" :xl="12">
        <TextWithSideImage 
            BackgroundColor="#f9fafb" 
            MinHeight="20"  
            Content="<h3>Markedsføring i den virkelige verden</h3>
            <p>Vi ønsker, at virksomheder kan planlægge og eksekvere sine outdoor markedsførings-aktiviteter på få minutter.</p>
            <p>Vælg blot en skærm og upload jeres kampange. <br />Nemmere bliver det ikke.</p>
            <h3>Support</h3>
            <p>Vi har support hele døgnet, og I kan altid få fat på os gennem vores live-chat, telefon eller kontakt@reklameskaerm.dk.</p>       
                    "
            :ImageUrl="require('@/assets/images/screens/netto.webp')"
            ImageAlt="Indendørs reklameskærm fra Servisto"
            Position="right"
        />
      </el-col>
      <el-col :xs="0" :sm="2" :md="2" :lg="4" :xl="6"></el-col>
    </el-row>

    <!-- FAQ -->
    <el-row :gutter="20" class="frontpage-container introduction-container" style="padding-top: 4rem; padding-bottom: 6rem;">
      <el-col :span="24" class="center-text">
        <h2>Ofte stillede spørgsmål</h2>
      </el-col>
      <el-col :xs="0" :sm="2" :md="2" :lg="4" :xl="6"></el-col>
      <el-col :xs="24" :sm="10" :md="10" :lg="8" :xl="6" class="faq-left">
        <accordion Title="Hvor mange gange bliver min reklame vist på skærmen?" Content="Din reklame vil som minimum blive vist hvert tiende gang på den valgte skærm. Antallet af gange man bliver vist varierer, da nogle skærme viser en reklame i 5 sekunder, og andre i 30 sekunder. Hertil er det forskelligt om den pågældende skærm er slukket i nattetimerne. Man er dog altid sikret, at sin reklame vises som minimum hvert tiende gang." />
        <accordion Title="Skal jeg betale, før jeg kan uploade min reklame?" Content="Betalingen skal foretages inden upload af jeres reklame, da der er tale om en reservation af pladsen på skærmen. Når betalingen er gennemført, vil skærmen kort tid efter være tilgængelig på jeres profil → Min konto → Booking, hvor I kan tilføje jeres reklame." />
        <accordion Title="Hvordan uploader jeg grafik til skærmen?" Content="Når betalingen er gennemført, vil skærmen kort tid efter være tilgængelig på jeres profil → Min konto → Booking, hvor I kan tilføje jeres reklame." />
      </el-col>
      <el-col :xs="24" :sm="10" :md="10" :lg="8" :xl="6" class="faq-right">
                <accordion Title="Rabat ved køb af flere uger?" Content="Ved køb af fire uger eller mere på samme skærm, vil man kunne opnå mængderabat. Denne mængderabat kan aflæses på profilen for den enkelte skærm. <br /> <br />Priserne for en reklameplads fastsættes af skærmens driftsoperatør." />
        <accordion Title="Hvordan får jeg lavet reklamen til skærmen?" Content="Vi hjælper gerne med at tilpasse jeres nuværende grafik eller kreere en helt ny reklame til skærmene. Skriv blot til kontakt@reklameskaerm.dk eller giv Martin et kald på 93609001." />
        <accordion Title="Jeg har en skærm. Kan den repræsenteres på Reklameskærm.dk?" Content="Det er meget muligt, at vi kan indgå et samarbejde, hvor jeres skærm kan være repræsenteret på vores bookingportal. Skriv en mail til kontakt@reklameskærm.dk eller giv Martin et kald på 93609001." />
      </el-col>
      <el-col :xs="0" :sm="2" :md="2" :lg="4" :xl="6"></el-col>
    </el-row>

    <!-- Become a partner -->
    <el-row class="frontpage-container" style="padding-top: 4rem; padding-bottom: 4rem;">
      <el-col :span="24" class="center-text">
        <h2 style="padding-right: 1rem; padding-left: 1rem;">Vil du samarbejde med os?</h2>
      </el-col>
      <el-col :xs="0" :sm="4" :md="6" :lg="7" :xl="8"></el-col>
      <el-col :xs="24" :sm="16" :md="12" :lg="10" :xl="8" class="breadtext">
        <p>Vi samarbejder med ejere af skærme, medie- og marketingsbureauer.</p>
        <p>Hvis du <b>ejer en reklameskærm</b> eller ønsker <b>en reklameskærm</b>, 
        kan du læse mere og kontakte os omkring mulighederne</p>
        
        <a href="/hjaelp?goTo=ForSkaermudbyder">
          <button class="btn btn-purple btn-rounded">
              Læs mere om mulighederne her
          </button>
        </a>

        <p>Er du en del af et <b>bureau</b>, som ønsker at udbyde annonceløsninger på storskærme, vil et samarbejde med os kunne istandsættes.</p>
        <p>Skriv til os på <a href="mailto:kontakt@reklameskaerm.dk">kontakt@reklameskaerm.dk</a></p>.
      </el-col>
      <el-col :xs="0" :sm="4" :md="6" :lg="7" :xl="8"></el-col>

    </el-row>


    


  </div>
</template>

<script>
import MapBox from '../components/shared/Map.vue';
import spotCard from '../components/layouts/SpotCard.vue'
import axios from 'axios';
import qs from 'qs'
import MetaData from '../components/shared/MetaData.vue'
import Accordion from '../components/layouts/Accordion.vue'
import Textblock from '../components/layouts/Textblock.vue';
import TextWithSideImage from '../components/layouts/TextWithSideImage.vue';

export default {
  name: 'Frontpage',
  components: { MapBox, spotCard, MetaData, Accordion, Textblock,TextWithSideImage },
  data() {
    return {
      topSpots: [],
      topSpotCards: [],
      spotCount: 40,
      mapSettings: {
        showPopup: true,
        spots: []
      }
    }
  },
  mounted() {
    this.getSpotsForFrontpage();
  },
  methods: {
    getElementY(query) {
      return window.pageYOffset + document.querySelector(query).getBoundingClientRect().top - document.querySelector(".header").offsetHeight
    },
    getSpotsForFrontpage() {
      var query = qs.stringify({
        populate: '*'
      });
      
      axios.get(`${process.env.VUE_APP_STRAPI_API_URL}spots/?${query}`, )
          .then(response => {
            this.topSpots = response.data.data;
            this.generateDataForTopSpotsCards();
            this.initializeSpotMap();
      })
    },
    generateDataForTopSpotsCards() {
      if(this.topSpots.length > 0) {
        this.spotCount = this.topSpots.length;
        for(var i = 0; i < 6; i++) {
          var spotData = this.topSpots[i];
          this.topSpotCards.push({
            id: spotData.id,
            link: spotData.attributes.HumanizedLink,
            title: spotData.attributes.Title,
            region: spotData.attributes.region.data.attributes.Name,
            city: spotData.attributes.City,
            viewsPrWeek: spotData.attributes.ViewsPrWeek,
            exposure: spotData.attributes.Exposure,
            pricePrWeek: spotData.attributes.PricePrWeek,
            imageUrl: spotData.attributes.Images.data[0].attributes.formats.small.url,
            altAttribute: spotData.attributes.Images.data[0].attributes.alternativeText,
            Campaign: spotData.attributes.spot_savings.data[0]?.attributes
          });
        }
      }


    },
    initializeSpotMap() {
      for(var i = 0; i < this.topSpots.length; i++) {
        var spotData = this.topSpots[i];
        this.mapSettings.spots.push({
          id: spotData.id,
          Link: spotData.attributes.HumanizedLink,
          PositionLong: spotData.attributes.PositionLong,
          PositionLat: spotData.attributes.PositionLat,
          Show: true,
          ShowPopUp: true,
          Title: spotData.attributes.Title,
          Region: spotData.attributes.region.data.attributes.Name,
          City: spotData.attributes.City,
          ImageUrl: spotData.attributes.Images.data[0].attributes.formats.thumbnail.url
        })
      }
      
      this.$refs.mapbox.initializeMap();
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.topbox {
  padding-top: 8rem;
  padding-bottom: 6rem;
  width: 100%;
  background-color: #f9fafb;
  -moz-box-shadow:    3px 1px 25px 12px #f0f0f0;
  -webkit-box-shadow: 3px 1px 25px 12px #f0f0f0;
  box-shadow:         3px 1px 25px 12px #f0f0f0;
  border-bottom: 1px solid #f6f6f6;
}


.topbox h1 {
  font-size: 52px;
  min-height: 7rem;
  line-height: 4rem;
}

.topbox h1 span {
  font-weight: 400 !important;
}

.ifYou ul {
  font-size: 18px;
}

.ifYou ul li {
  height: auto;
}

.yes li {
  background: url('~@/assets/images/Checkmark.svg') no-repeat left top; /* <-- change `left` & `top` too for extra control */
  padding: 0px 0px 10px 40px;
  /* reset styles (optional): */
  list-style: none;
  height: 32px;
}

.no li {
  background: url('~@/assets/images/Denied.svg') no-repeat left top; /* <-- change `left` & `top` too for extra control */
  padding: 0px 0px 10px 40px;
  /* reset styles (optional): */
  list-style: none;
  height: 32px;
}


@media only screen and (max-width: 992px) {
  .topbox {
    padding-bottom: 2rem;
  }
}

@media only screen and (min-width: 768px) {
    .frontpage {
    border-top: 1px solid rgb(204 204 204);
    }

    .frontpage-background {
      height: 400px !important;
    }
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  .welcome-message {
      font-size: 18px !important;
  }

  .spotCardsList:nth-child(-n+4) {
    display: none;
  }

  .ifYou ul li {
    height: auto;
  }

  .topbox {
    margin-top: 16%;
    padding-top: 0rem;
    padding-left: 1rem !important;
    padding-right: 1rem;
  }


.topbox h1 {
  font-size: 36px;
  min-height: 7rem;
  line-height: 3.5rem;
}

.topbox h1 span {
  font-weight: 400 !important;
}

.topbox p {
  padding-bottom: 1.5rem;
}

  .customer-images img {
    width: 50% !important;
    padding-left: 25%;
  }

  .welcome-message-container {
    margin: 0;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }

  .spotCard-col {
    width: 100% !important;
  }

  .mapboxref {
    margin-top: 40px;
  }

  .find-the-perfect-screen h2, .find-the-perfect-screen div {
    text-align: center;
  }

  .breadtext, .step-description {
    padding-left: 20px;
    padding-right: 20px;
  }


  .find-the-perfect-screen .seeScreens {
    text-align: center !important;
  }

}


.welcome-message {
  text-align: center;
  font-weight: 600;
  padding-top: 30px;
  font-size: 36px;
}

.welcome-first-part {
  color: #272727;
}

.welcome-second-part {
  color: #9433ff;
}

.max-width {
  width: 100%;
  max-width: 1488px;
  margin: 0 auto;
}

.frontpage-container h2 {
  font-size: 28px;
  color: black !important;
}

.find-the-perfect-screen {
  margin-top: 25px;
}

.find-the-perfect-screen h2 {
  line-height: 25px;
}

.first-container {
  margin-top: 0px !important;
}

.top-cards {
  width: 880px;
}

.introduction-container {
  margin-top: 20px;
  background-color: rgb(241, 241, 241);
  padding-top: 15px;
  padding-bottom: 15px;
  border-top: 1px solid #e9e9e9;
  border-bottom: 1px solid #e9e9e9;
}

.steps {
  font-size: 24px;
  font-weight: 600;
}

.steps-row {
  width: 100%;
  max-width: 1000px;
  left: 50%;
  transform: translateX(-50%);
  padding-bottom: 20px;
}

.steps-row h5 {
  padding-top: 0px !important;
}

.step-description {
  font-size: 16px;
  font-weight: 400;
}


.become-partner-btn {
  padding-top: 20px;
  padding-bottom: 40px;
}

.mapboxref {
    margin-top: 40px;
  }

.frontpage-background {
  width: 100%;
  height: calc(100vh);
  background-image: url('~@/assets/images/Backgrounds/P1110118.jpg');
  background-size:     cover;
  background-repeat:   no-repeat;
  background-position: bottom center; 
  position: relative;
  -moz-box-shadow:    3px 1px 25px 12px #ccc;
  -webkit-box-shadow: 3px 1px 25px 12px #ccc;
  box-shadow:         3px 1px 25px 12px #ccc;
  border-bottom: 1px solid #c2c2c2;
  -webkit-filter: blur(2px);
  filter: blur(2px);
}

.top-spots {
  margin-top: 40px !important;
}

.welcome-message-container-before {
    margin: 0;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

.welcome-message-container .message-text {
  height: 50px;
  font-size: 24px;
}

.welcome-message-container .sub-message {
  font-size: 14px;
  height: 20px;
  line-height: 0px;
}

.welcome-message-container:hover {
  opacity: 0.95;
  top: 220px;
  transition: 0.2s;
}

.welcome-message-container {
  position: absolute;
  transition: 0.2s;
  height: 90px;
  cursor: pointer;
  padding-top: 15px;
  color: white !important;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  border: none;
  font-size: 28px;
  max-width: 400px;
  width: 90%;
  z-index: 99;
  border-radius: 25px;
  left: 50%;
  transform: translateX(-50%);
  transition: translateY(30%);
  top: 225px;
  background:linear-gradient(45deg,#9334ff,#6EC7AB); 

}



.row {
  padding-bottom: 40px;
}

.see-more {
  background-color: white;
  width: 50px;
  height: 50px;
  border-radius: 32px;
  position: absolute;
  text-align: center;
  bottom: 15%;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  font-size: 32px;
  cursor: pointer;
  transition: 0.2s;
  animation: myAnim 1s ease 0s infinite alternate-reverse none;
}

.see-more:hover {
  opacity: 0.7;
  transition: 0.2s;
}

@keyframes myAnim {
  0% {
    transform: scale(0.5);
  }
  
  100% {
    transform: scale(1);
  }
}

.customer-images {
  margin-top: 40px;
}

.customer-images img {
  width: 100%;
  cursor: pointer;
  transition: 0.2s;
}

.customer-images img:hover {
  opacity: 0.7;
  transition: 0.2s;
  transform: scale(1.1);
}

.workerCard {
    width: 100%;
    max-width: 180px;
    margin: 0 auto;
    border-radius: 6px;
    text-align: center;
}

.martin-img {
  background-image: url('~@/assets/images/martin_3.webp');
  background-size:     cover;
  background-repeat:   no-repeat;
  background-position: 0px 0px; 
  width:100%;
  height: 200px;
}

.steffen-img {
  background-image: url('~@/assets/images/steffen_3.webp');
  background-size:     cover;
  background-repeat:   no-repeat;
  background-position: 0px 0px; 
  width:100%;
  height: 200px;
}

.workerCard img {
    padding: 20px 5px 0px 5px;
    width: 100%;
}

.workerCard .Name {
    float: left;
    width: 100%;
    font-size: 15px;
    font-weight: 600;
    height: 20px;
    padding-top: 5px;
    background-color: #f1f1f1;
    border-radius: 5px 5px 0px 0px;
}

.workerCard .Role {
    float: left;
    width: 100%;
    padding-bottom: 5px;
    font-size: 13px;
    color: rgb(129, 129, 129);
    font-weight: 600;
    background-color: #f1f1f1;
    border-radius: 0px 0px 5px 5px;
}

.iframeContainer {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
}

/* Then style the iframe to fit in the container div with full height and width */
.responsive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border: 0px;
}
</style>
