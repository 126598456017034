import { createRouter, createWebHistory } from 'vue-router'
import Frontpage from '../views/Frontpage.vue'
import SpotOverview from '../views/SpotOverview.vue'
import store from '../store'
import sitemap from '../router/sitemap.vue'

const routes = [
  {
    path: '/',
    name: '',
    component: Frontpage
  },
  {
    path: '/oversigt',
    name: 'Oversigt',
    component: SpotOverview
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
  ,
  {
    path: '/reklameskaerme',
    name: 'reklameskaerme',
    component: () => import(/* webpackChunkName: "about" */ '../views/SpotOverview.vue')
  },
  {
    path: '/reklameskaerm/:spotID',
    name: 'reklameskaerm',
    component: () => import('../views/SpotDetails.vue')
  },
  {
    path: '/min-konto',
    name: 'min-konto',
    meta: {
      requiresAuth: true
    },
    component: () => import('../views/AccountPage.vue')
  },
  {
    path: '/adminpanel',
    name: 'adming-page',
    meta: {
      requiresAuth: true,
    },
    component: () => import('../views/AdminPage.vue')
  },
  {
    path: '/kurv',
    name: 'kurv',
    component: () => import('../views/ShoppingCart.vue')
  },
  {
    path: '/hjaelp',
    name: 'hjaelp',
    component: () => import('../views/HelpPage.vue')
  },
  {
    path: '/artikler-og-cases',
    name: 'artikler-og-cases',
    component: () => import('../views/ArticlesAndCases.vue')
  },
  {
    path: '/faa-jeres-egen-reklameskaerm',
    name: 'GetYourOwnPage',
    component: () => import('../views/GetYourOwnScreen.vue')
  },
  {
    path: '/artikler-og-cases/case/:caseID',
    name: 'case',
    component: () => import('../views/Case.vue')
  },
  {
    path: '/artikler-og-cases/artikel/:articleID',
    name: 'artikel',
    component: () => import('../views/Article.vue')
  },
  {
    path: '/payment',
    name: 'payment',
    component: () => import('../views/Payment.vue')
  },
  {
    path: '/decline',
    name: 'decline',
    component: () => import('../views/Decline.vue')
  },
  {
    path: '/resetPassword',
    name: 'resetPassword',
    component: () => import('../views/Resetpassword.vue')
  },
  {
    path: '/sitemap',
    name: 'sitemap',
    component: () => sitemap,
    beforeEnter() {
      window.location.href = "https://reklameskaerm-strapi-prod.herokuapp.com/sitemap/index.xml";
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (!store.getters.isLoggedIn) {
      next({ path: '/' })
    } else {
      next() // go to wherever I'm going
    }
  } else {
    next() // does not require auth, make sure to always call next()!
  }
})

export default router
