<template>
    <div id="map" :style="style" class="map"></div>
</template>

<script lang="ts">
import mapboxgl from 'mapbox-gl'
export default {
  name: 'MapBox',
  props: {
      spots: [],
      propShowPupup: {
          type: Boolean,
          default: false,
      },
      height: {
          type: Number,
          default: 700
      }
  },
  data() {
      return {
          mapboxAccessToken: 'pk.eyJ1Ijoic3RlZjQ5MDUiLCJhIjoiY2txZjZ2YWwyMGV2dTJ2cXU1OGw2NGtsayJ9.KixQTvuv2wavDwMbPW2u1g',
          mapBox: {},
          markers: []
      }
  },
  methods: {
      initializeMap() {
          mapboxgl.accessToken = this.mapboxAccessToken;

          this.mapBox = new mapboxgl.Map({
              container: 'map',
              style: 'mapbox://styles/mapbox/streets-v11',
              center: [10.5, 56.35],
              zoom: 5.7,
          });

          this.mapBox.addControl(new mapboxgl.NavigationControl());

          this.refreshMarkersOnMap();
      },
      refreshMarkersOnMap() {
        if(this.markers.length > 0)
        {
            for(var markerIndex = 0; markerIndex < this.markers.length; markerIndex++) 
            {
            this.markers[markerIndex].remove();
            }
        }

        for(var index = 0; index < this.spots.length; index++)
        {
            var spot = this.spots[index];
            if(spot.Show) {
                if(spot.ShowPopUp && this.propShowPupup) {
                    

                    const popup = new mapboxgl.Popup({ offset: 25, closeButton: false, maxWidth: 1000}).setHTML(
                    "<div style='width: 250px;'>" + 
                    "<div style='float: left;'><img style='width: 100%; border-radius: 10px; border: 1px solid rgb(225, 225, 225);box-shadow: 0 4px 6px -1px rgb(0 0 0 / 10%), 0 2px 4px -1px rgb(0 0 0 / 6%);' src='" + spot.ImageUrl +"'></div>" +
                    "<div style='float: left; font-weight: 600; width: 100%; font-size: 14px;'>" + spot.Title +"</div>" +
                    "<div style='float: left; font-weight: 600; width: 60%; opacity: 0.7;'>" + spot.Region + "<span> > </span>" + spot.City + "</div>" +
                    "<div style='float: left; font-weight: 600; width: 40%; opacity: 0.7;'><a class='test' href='/reklameskaerm/" + spot.Link + "'><button class='btn btn-purple btn-small center-contet'>Se skærm</button></a></div>" +
                    "</div>"
                    );

                    var markerWithPupup = new mapboxgl.Marker({color: '#9433ff'})
                    .setLngLat([spot.PositionLong, spot.PositionLat])
                    .setPopup(popup)
                    .addTo(this.mapBox);

                    this.markers.push(markerWithPupup);
                }
                else {
                    var markerWithoutPopup = new mapboxgl.Marker({color: '#9433ff'})
                    .setLngLat([spot.PositionLong, spot.PositionLat])
                    .addTo(this.mapBox);

                    this.markers.push(markerWithoutPopup);
                }
            }
        }
      }
  },
  computed: {
    style () {
        return 'height: ' + this.height + 'px';
      }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.map {
  width: 99%;
  position: relative;
}

.test   {
    outline-offset: 0px !important;
    outline: none !important;
}

.map:after {
    content  : "";
    position : absolute;
    z-index  : 1;
    bottom   : 0;
    left     : 0;
    pointer-events   : none;
    box-shadow: 0px 0px 3px 5px #f9fafb inset;
    width    : 100%;
    height   : 100%;
}
</style>
